import {Trans} from '@ui/i18n/trans';
import {Button} from '@ui/buttons/button';
import { Link } from 'react-router-dom';
import imgUrl1 from './404-1.png';
import imgUrl2 from './404-2.png';

export function NotFoundPage() {
  return (
    <div className="lg:px-96 lg:py-96 md:py-80 md:px-176 px-16 py-96 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-112 gap-64">
      <div className="xl:pt-96 w-full xl:w-1/2 relative pb-48 lg:pb-0">
        <div className="relative">
          <div className="absolute">
            <div className="relative z-10">
              <h1 className="my-8 text-main font-bold text-2xl">
                {/* Texto modificado con el nuevo mensaje */}
                <Trans message="Adquiere un plan para acceder" />
              </h1>
              {/* Detalles de la suscripción o instrucciones adicionales pueden ir aquí */}
              <Button
                className="my-8"
                elementType={Link}
                size="lg"
                to="/pricing" // Actualizado para dirigir al usuario a la página de precios
                variant="flat"
                color="primary"
              >
                {/* Texto del botón para adquirir la suscripción */}
                <Trans message="¡Obtén tu suscripción ahora!" />
              </Button>
            </div>
          </div>
          <div className="dark:opacity-5">
            <img src={imgUrl2 as any} alt="" />
          </div>
        </div>
      </div>
      <div className="dark:opacity-80">
        <img src={imgUrl1 as any} alt="" />
      </div>
    </div>
  );
}